
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {

		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages


				//Current page gets active nav class
				$('.current-menu-item').addClass('active');
				// //footer sign up form
				$("#gform_submit_button_4").appendTo(".ginput_container_email");


				// load
				$(window).on('load', function(){
					if ($('.banner.hero').length > 0){
						$('.banner.hero .cnt .subheader, .banner.hero .cnt h1, .banner.hero .bg').addClass('on');
						setTimeout(function(){
							$('.banner.hero .btn').addClass('on');
						}, 600);
					}
					if ($('.banner.inside').length > 0){
						$('.banner.inside .cnt .page-name, .banner.inside .cnt h1').addClass('on');
					}
					if ($('.banner.insight').length > 0){
						$('.banner.insight .cnt .page-name, .banner.insight .cnt h1, .banner.insight .cnt .author, .banner.insight .cnt .excerpt, .banner.insight .cnt .read-more, .banner.insight .thumb, .insight-filter').addClass('on');
					}
				});

				// reveal
				var site_height = $(window).height() - 100;
				if ($('.reveal').length > 0){
			        $('.reveal').each(function(e){
			            var reveal = $(this);
			            var waypoint_reveal = new Waypoint({
			                element: reveal[0],
			                handler: function(direction) {
			                    if (direction === 'down'){
			                        reveal.addClass('on');
			                    }
			                },
			                offset: "95%"
			            });
			        });
			    }

				if ($('.reveal-left').length > 0){
			        $('.reveal-left').each(function(e){
			            var reveal_left = $(this);
			            var waypoint_reveal_left = new Waypoint({
			                element: reveal_left[0],
			                handler: function(direction) {
			                    if (direction === 'down'){
			                        reveal_left.addClass('on');
			                    }
			                },
			                offset: "95%"
			            });
			        });
			    }

				// nav
				$('.hamburger').on('click', function (event) {
					event.preventDefault();
					event.stopPropagation();
					$(this).toggleClass('is-active');
					if ($(this).hasClass('is-active')) {
						$('.nav').addClass('open');
						$('body').addClass('no-scroll');
					} else {
						$('.nav').removeClass('open');
						$('body').removeClass('no-scroll');
					}
				});

				$('.nav').on('click', function (event){
					event.stopPropagation();
				});

				$('.parent a .ico').on('click', function (event){
					event.preventDefault();
					if ($(this).hasClass('open')) {
						$(this).removeClass('open');
						$(this).parent().next().slideUp(400);
					} else {
						$(this).addClass('open');
						$(this).parent().next().slideDown(400);
					}
				});

				// video
				function fallback(video){
					var img = video.querySelector('img');
					if (img){
						video.parentNode.replaceChild(img, video);
					}
				}

				// filters
				$('.insight-filter .topics > li > a, .insight-filter .types > li > a, .search-filter .topics > li > a').on('click', function (event){
					event.preventDefault();
					if ($(this).hasClass('open')){
						$(this).removeClass('open');
						$(this).next().slideUp(400);
					} else {
						$(this).addClass('open');
						$(this).next().slideDown(400);
					}
				});

				// show more less
				var showChar = 450;
				var ellipsestext = "...";
				var moretext = "Expand Description";
				var lesstext = "Collapse Description";

				$('.more').each(function() {
					var content = $(this).html();
					if(content.length > showChar) {
						var c = content.substr(0, showChar);
						var h = content.substr(showChar, content.length - showChar);
						var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + ' <span class="ico"></span></a></span>';
						$(this).html(html);
					}
				});

				$(".morelink").click(function(){
					if($(this).hasClass("less")) {
						$(this).removeClass("less");
						$(this).html(moretext + '<span class="ico"></span>');
					} else {
						$(this).addClass("less");
						$(this).html(lesstext + '<span class="ico"></span>');
					}
					$(this).parent().prev().toggle();
					$(this).prev().toggle();
					return false;
				});

				// new
				$('.jobs-section .job-post .subheader a').on('click', function (event){
					event.preventDefault();
					var job = $(this).data('job');
					if ($(this).hasClass('open')){
						$(this).removeClass('open');
						$(this).parent().next().slideUp(400);
					} else {
						$(this).addClass('open');
						$(this).parent().next().slideDown(400);
					}
				});

				//search
				$('header .utility #search input[type=text]').on('focus', function(){
					$(this).parent().addClass('active');
				});
				$('header .utility #search input[type=text]').on('blur', function(){
					$(this).parent().removeClass('active');
				});

				// scrollto
				if ($('.scrollto').length > 0){
					$('.scrollto').on('click', function (e) {
						e.preventDefault();
						var section = $(this).attr('href');
						$('html, body').animate({
							scrollTop: $(section).offset().top + -50
						}, 1000);
					});
				}

				// hightlight on scroll
				if ($('.solutions-box-tabs aside').length > 0){
					$('.solutions-box').each(function(e){
						var box = $(this);
						var boxTab = $(this).attr('id');
						var waypointBox = new Waypoint({
							element: box[0],
							handler: function(direction) {
								if (direction === 'down'){
									$('.solutions-box-tabs .tab').removeClass('active');
									$('.'+boxTab).addClass('active');
								}
							},
							offset: 200
						});
						waypointBox = new Waypoint({
							element: box[0],
							handler: function(direction) {
								if (direction === 'up'){
									$('.solutions-box-tabs .tab:not(.box1)').removeClass('active');
									$('.'+boxTab).addClass('active');
								}
							},
							offset: -100
						});
					});
				}

				if ($('.sticky-what').length > 0){
					if ($(window).width() > 768){
						$('.sticky-what').each(function(i){
							var sidebar_height = $(this).children().children('#sidebar-what').children('.sidebar__inner-what').height();
							var content_height = $(this).children().children('.content').height();
							if (content_height > sidebar_height){
								var sidebar = new StickySidebar('#sidebar-what', {
									containerSelector: '#main-content-what',
									innerWrapperSelector: '.sidebar__inner-what',
									topSpacing: 20,
									bottomSpacing: 0
								});
							}
						});
					}
				}

				if ($('.sticky-quote').length > 0){
					if ($(window).width() > 768){
						$('.sticky-quote').each(function(i){
							var sidebar_height = $(this).children().children('#sidebar-quote').children('.sidebar__inner-quote').height();
							var content_height = $(this).children().children('.content').height();
							if (content_height > (sidebar_height * 2)){
								var sidebar = new StickySidebar('#sidebar-quote', {
									containerSelector: '#main-content-quote',
									innerWrapperSelector: '.sidebar__inner-quote',
									topSpacing: 20,
									bottomSpacing: 0
								});
							}
						});
					}
				}

				if ($('#sticky').length > 0){
					if ($(window).width() > 768){
						var sidebar = new StickySidebar('#sidebar', {
							containerSelector: '#main-content',
							innerWrapperSelector: '.sidebar__inner',
							topSpacing: 20,
							bottomSpacing: 0
						});
					}
				}


				// plus
				if ($('.plus').length){
					$('.related-insight-header').on('click', function () {
						$(this).parent().next().slideToggle('800', function(){
							Waypoint.refreshAll();
						});
						$(this).children('.plus').toggleClass('opened');
					});
				}

				$(window).on('load', function(){
					if ($('#error').length){
						$('html, body').animate({
							scrollTop: $('#error').offset().top
						}, 1000);
					}
					if ($('.footer-thankyou-container').length){
						$('html, body').animate({
							scrollTop: $('.footer-thankyou-container').offset().top
						}, 1000);
					}
				});


				// parallax elements
				if ($(window).width() > 1024) {
                    $(window).on('scroll', function () {
						$('.parallax').each(function(){
							var this_top = $(this).offset().top;
							var scrolled = $(window).scrollTop();
							var scroll_this = this_top - scrolled;
							$(this).css('transform', 'translate3d(0, ' + scroll_this * 0.2 + 'px, 0)');
							$(this).fadeIn(400);
						});
                    });
                }

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Home page
		'page_template_template_home': {
			init: function() {

				// KPIs
				var kpi_swiper = new Swiper('.stat-box .swiper-container', {
					autoplay: false,
					slidesPerView: 1,
					loop: true,
					speed: 600,
					effect: 'fade',
					spaceBetween: 0,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					},
					a11y: {
						enabled: true,
						prevSlideMessage: 'Previous slide',
						nextSlideMessage: 'Next slide',
						paginationBulletMessage: 'Go to slide {{index}}',
					},
					breakpoints: {

					},
				});

				$('.stat-box').each(function(e){
					var reveal_slider = $(this);
					var waypoint_reveal_slider = new Waypoint({
						element: reveal_slider[0],
						handler: function(direction) {
							if (direction === 'down'){
								kpi_swiper.autoplay.start();
							}
						},
						offset: '95%'
					});
				});

				$('.stat-box .icons li a').on('click', function (event){
					event.preventDefault();
					var slide = $(this).attr('href');
					kpi_swiper.slideTo(slide);
				});


				function runAnimation(){
					if ($('.grow').hasClass('on')){
						if (!$('#grow-anim').hasClass('ran')){
							$('#grow-anim').addClass('ran');
							var grow = $('#grow-anim').data('iframe');
							if ($('#grow-anim').prop('src', '')){
								$('#grow-anim').prop('src', grow);
								$('.grow .about-link a').addClass('off');
							}
						}
					}
					if ($('.perform').hasClass('on')){
						if (!$('#perform-anim').hasClass('ran')){
							setTimeout(function(){
								$('#perform-anim').addClass('ran');
								var perform = $('#perform-anim').data('iframe');
								if ($('#perform-anim').prop('src', '')){
									$('#perform-anim').prop('src', perform);
									$('.perform .about-link a').addClass('off');
								}
							}, 2500);
						}
					}
					if ($('.transform').hasClass('on')){
						if (!$('#transform-anim').hasClass('ran')){
							setTimeout(function(){
								$('#transform-anim').addClass('ran');
								var transform = $('#transform-anim').data('iframe');
								if ($('#transform-anim').prop('src', '')){
									$('#transform-anim').prop('src', transform);
									$('.transform .about-link a').addClass('off');
								}
							}, 5000);
						}
					}
				}

				$(window).on('load', function(){
					runAnimation();
					if ($('.banner.hero').length > 0){
						$('.banner.hero .cnt .subheader, .banner.hero .cnt h1, .banner.hero .bg').addClass('on');
						setTimeout(function(){
							$('.banner.hero .btn').addClass('on');
						}, 600);
					}
					if ($('.banner.inside').length > 0){
						$('.banner.inside .cnt .page-name, .banner.inside .cnt h1').addClass('on');
					}
					if ($('.banner.insight').length > 0){
						$('.banner.insight .cnt .page-name, .banner.insight .cnt h1, .banner.insight .cnt .author, .banner.insight .cnt .excerpt, .banner.insight .cnt .read-more, .banner.insight .thumb, .insight-filter').addClass('on');
					}
				});

				$(window).on('scroll', function(){
					if ($(window).width() > 768){
						if (!$('#grow-anim').hasClass('ran')){
							runAnimation();
						}
					} else {
						$('.grow').each(function(e){
							var grow = $(this);
							var waypoint_grow = new Waypoint({
								element: grow[0],
								handler: function(direction) {
									if (direction === 'down'){
										if (!$('#grow-anim').hasClass('ran')){
											$('#grow-anim').addClass('ran');
											var grow = $('#grow-anim').data('iframe');
											if ($('#grow-anim').prop('src', '')){
												$('#grow-anim').prop('src', grow);
											}
										}
									}
								},
								offset: '95%'
							});
						});
						$('.perform').each(function(e){
							var perform = $(this);
							var waypoint_perform = new Waypoint({
								element: perform[0],
								handler: function(direction) {
									if (direction === 'down'){
										if (!$('#perform-anim').hasClass('ran')){
											$('#perform-anim').addClass('ran');
											var perform = $('#perform-anim').data('iframe');
											if ($('#perform-anim').prop('src', '')){
												$('#perform-anim').prop('src', perform);
											}
										}
									}
								},
								offset: '95%'
							});
						});
						$('.transform').each(function(e){
							var transform = $(this);
							var waypoint_transform = new Waypoint({
								element: transform[0],
								handler: function(direction) {
									if (direction === 'down'){
										if (!$('#transform-anim').hasClass('ran')){
											$('#transform-anim').addClass('ran');
											var transform = $('#transform-anim').data('iframe');
											if ($('#transform-anim').prop('src', '')){
												$('#transform-anim').prop('src', transform);
											}
										}
									}
								},
								offset: '95%'
							});
						});
					}
				});



				// JavaScript to be fired on all pages
				var news_swiper = new Swiper('.insights-section .swiper-container', {
					autoplay: false,
					slidesPerView: 4,
					loop: true,
					speed: 1200,
					centeredSlides: true,
					spaceBetween: 20,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					},
					a11y: {
						enabled: true,
						prevSlideMessage: 'Previous slide',
						nextSlideMessage: 'Next slide',
						paginationBulletMessage: 'Go to slide {{index}}',
					},
					navigation: {
						nextEl: '.insights-section .next',
						prevEl: '.insights-section .prev',
					},
					breakpoints: {
						1640: {
							slidesPerView: 3,
							centeredSlides: false,
						},
						1280: {
							slidesPerView: 2,
							centeredSlides: false,
						},
						768: {
							slidesPerView: 1,
						}
					},
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Careers page
		'page_template_template_careers': {
			init: function() {
				// JavaScript to be fired on all pages
				var quote_swiper = new Swiper('.quote-slider .swiper-container', {
					autoplay: {
						delay: 6000,
					},
					slidesPerView: 1,
					loop: true,
					speed: 600,
					spaceBetween: 0,
					simulateTouch: false,
					queueStartCallbacks: true,
					queueEndCallbacks: true,
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					},
					a11y: {
						enabled: true,
						prevSlideMessage: 'Previous slide',
						nextSlideMessage: 'Next slide',
						paginationBulletMessage: 'Go to slide {{index}}',
					},
					pagination: {
						el: '.pagination',
						type: 'bullets',
						clickable: true,
					},
					breakpoints: {

					},
				});

				function photoShadows(){
					var left_shadow = $('.careers-photos-wrapper .photo:nth-child(2)').offset().left;
					var right_shadow = $('.careers-photos-wrapper .photo:nth-child(4)').offset().left;
					$('.careers-photos-wrapper .left-shadow').css('left', left_shadow + 'px');
					$('.careers-photos-wrapper .right-shadow').css('left', right_shadow + 'px');
				}
				photoShadows();

				$(window).on('resize', function(){
					photoShadows();
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Contact page
		'page_template_template_contact': {
			init: function() {
				// JavaScript to be fired on all pages

				// //footer sign up form
				$(".ginput_container_email #gform_submit_button_4").remove();

				/* map */
				var token ="pk.eyJ1IjoiamFkcm9lc2UiLCJhIjoiVlpnanBvayJ9.GH8f6KLAgcR8uzyix0Z0gg";

				var greenIcon = L.icon({
					iconUrl: 'http://dev.healthscape.sandbox6.cliquedomains.com/wp-content/themes/healthscape-theme/dist/images/pin.svg',
					iconSize:     [40, 58],
					shadowSize:   [0, 0],
					iconAnchor:   [20, 58],
					shadowAnchor: [0, 0],
					popupAnchor:  [0, 0]
				});

				var map = L.map('map', { zoomControl:false }).setView([41.880341,-87.630074], 14);

				L.marker([41.880341,-87.630074], {icon: greenIcon})
					.addTo(map);
				var gl = L.mapboxGL({
					accessToken: token,
					style: 'mapbox://styles/mapbox/streets-v10'
				}).addTo(map);

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},


		// Insights Post
		'single': {
			init: function() {
				// JavaScript to be fired on all pages
				function setLeft(){
					var left = $('.single-section .col-xs-2:last-child').position().left;
					$('.share-side').css('left', left+'px');
				}

				if ($(window).width() > 1024) {

					var waypointShare = new Waypoint({
						element: document.getElementById('share-trigger'),
						handler: function(direction) {
							if (direction === 'down') {
								$('#share').addClass('fixed');
							}
							if (direction === 'up') {
								$('#share').removeClass('fixed');
							}
						},
						offset: '0%'
					});
					var waypointRelated = new Waypoint({
						element: document.getElementById('related'),
						handler: function(direction) {
							if (direction === 'down') {
								$('#share').addClass('fade');
							}
							if (direction === 'up') {
								$('#share').removeClass('fade');
							}
						},
						offset: '100%'
					});

					setLeft();

					$(window).on('resize', function(){
						setLeft();
					});
				}

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// search page
		'search': {
			init: function() {
				// JavaScript to be fired on all pages
				var ajaxpagination = {
					"ajaxurl":"/wp-admin/admin-ajax.php"
				};
				var searchKeyword = $("#wpa_label_s").val();
				$('.topic').on('click', function() {
					var name = $(this).attr('id');
					$.ajax({
						url: ajaxpagination.ajaxurl,
						type: 'post',
						cache: false,
						data: {
							'variable': name,
							'search': searchKeyword,
							action: 'ajax_pagination',
						},
						beforeSend: function() {
							$('.insight-post-row').remove();
							$('.insight-section').empty();
							$('.page-header').html('');
							$('.insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
						},
						success: function(html) {
							setTimeout(function(){
								$('.insight-post-row').addClass('loaded');
							}, 500);
							$('.load-more-content').remove();
							$('.insight-section').append(html);
						},
					});
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		 // Insights page
		'page_template_template_all_posts': {
			init: function() {
				// JavaScript to be fired on all pages

				// WP Admin AJAX page
				var ajaxpagination1 = {
					"ajaxurl":"/wp-admin/admin-ajax.php"
				};

				//as user scrolls down another ajax call is made to load and append additional insights
				var waypoint = new Waypoint({
					element: document.getElementById('insight-section'),
					handler: function(direction) {
						if (direction === 'down') {
							//ajax call to load additioanl posts
							$.ajax({
								url: ajaxpagination1.ajaxurl,
								type: 'post',
								cache: false,
								data: {
									action: 'all_insights_load',
								},
								beforeSend: function() {
									$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
								},
								success: function(ajaxLoad) {
									$('.load-more-content').remove();
									$("#insight-section").append(ajaxLoad);
									waypoint.destroy();
								},
								//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
								complete: function() {
									setTimeout(function(){
										$('.reveal').each(function(e){
											var reveal = $(this);
											var waypoint_reveal = new Waypoint({
												element: reveal[0],
												handler: function(direction) {
													if (direction === 'down'){
														reveal.addClass('on');
													}
												},
												offset: '95%'
											});
										});
									}, 100);
								}
							});
						}
					},
					offset: '-100%'
				});

				//All Insight Topic Filter
				var ajaxpagination2 = {
					"ajaxurl":"/wp-admin/admin-ajax.php"
				};
				$('.topic').on('click', function(e) {
					e.preventDefault();
					$(this).toggleClass('activeTopic');
					//when drop down menu topic is clicked store the topic name in topicName
					var topicName = $(this).attr('id');
					$(this).parent().parent().prev().children('.current').text(topicName);
					$(this).parent().parent().prev().removeClass('open');
					$(this).parent().parent().slideUp(400);

					var activeType = $('.types > li > a > .current').text();

					if ( $('.type').hasClass('activeType') && $('.topic').hasClass('activeTopic')) {
					$.ajax({
						url: ajaxpagination2.ajaxurl,
						type: 'post',
						cache: false,
						data: {
							'topicName':topicName,
							'typeName': activeType,
							action: 'all_insights_insight_filtering_topics',
						},
							beforeSend: function() {
							$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
							$('.blue-circle-large.three').remove();
							$('.blue-shape-small.two').remove();
						},
							success: function(insightFilter) {
							waypoint.destroy();
							$('.load-more-content').remove();
							$('#insight-section').empty();
							$("#insight-section").append(insightFilter);
						},
						//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
						complete: function() {
							setTimeout(function(){
								$('.reveal').each(function(e){
									var reveal = $(this);
									var waypoint_reveal = new Waypoint({
										element: reveal[0],
										handler: function(direction) {
											if (direction === 'down'){
												reveal.addClass('on');
											}
										},
										offset: '95%'
									});
								});
							}, 100);
						}
					});
				}

				if ( $('.topic').hasClass('activeTopic') && !$('.type').hasClass('activeType') ) {
									$.ajax({
										url: ajaxpagination3.ajaxurl,
										type: 'post',
										cache: false,
										data: {
											'topicName':topicName,
											action: 'all_insights_insight_filtering_topics_only',
										},
										beforeSend: function() {
											$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
											$('.blue-circle-large.three').remove();
											$('.blue-shape-small.two').remove();
										},
										success: function(insightFilterType) {
											waypoint.destroy();
											$('.load-more-content').remove();
											$('#insight-section').empty();
											$("#insight-section").append(insightFilterType);
										},
										//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
										complete: function() {
											setTimeout(function(){
												$('.reveal').each(function(e){
													var reveal = $(this);
													var waypoint_reveal = new Waypoint({
														element: reveal[0],
														handler: function(direction) {
															if (direction === 'down'){
																reveal.addClass('on');
															}
														},
														offset: '95%'
													});
												});
											}, 100);
										}
									});
								};

				});

				//All Insight Type Filter
				var ajaxpagination3 = {
					"ajaxurl":"/wp-admin/admin-ajax.php"
				};
				$('.type').on('click', function(e) {
					e.preventDefault();
					$(this).toggleClass('activeType');
					//when drop down menu topic is clicked store the topic name in topicName
					var typeName = $(this).attr('id');
					$(this).parent().parent().prev().children('.current').text(typeName);
					$(this).parent().parent().prev().removeClass('open');
					$(this).parent().parent().slideUp(400);

					var activeTopic = $('.topics > li > a > .current').text();

if ( $('.type').hasClass('activeType') && $('.topic').hasClass('activeTopic')) {
					$.ajax({
						url: ajaxpagination3.ajaxurl,
						type: 'post',
						cache: false,
						data: {
							'typeName': typeName,
							'topicName':activeTopic,
							action: 'all_insights_insight_filtering_types',
						},
						beforeSend: function() {
							$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
							$('.blue-circle-large.three').remove();
							$('.blue-shape-small.two').remove();
						},
						success: function(insightFilterType) {
							waypoint.destroy();
							$('.load-more-content').remove();
							$('#insight-section').empty();
							$("#insight-section").append(insightFilterType);
						},
						//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
						complete: function() {
							setTimeout(function(){
								$('.reveal').each(function(e){
									var reveal = $(this);
									var waypoint_reveal = new Waypoint({
										element: reveal[0],
										handler: function(direction) {
											if (direction === 'down'){
												reveal.addClass('on');
											}
										},
										offset: '95%'
									});
								});
							}, 100);
						}
					});
				};

				if ( $('.type').hasClass('activeType') && !$('.topic').hasClass('activeTopic') ) {
									$.ajax({
										url: ajaxpagination3.ajaxurl,
										type: 'post',
										cache: false,
										data: {
											'typeName': typeName,
											action: 'all_insights_insight_filtering_types_only',
										},
										beforeSend: function() {
											$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
											$('.blue-circle-large.three').remove();
											$('.blue-shape-small.two').remove();
										},
										success: function(insightFilterType) {
											waypoint.destroy();
											$('.load-more-content').remove();
											$('#insight-section').empty();
											$("#insight-section").append(insightFilterType);
										},
										//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
										complete: function() {
											setTimeout(function(){
												$('.reveal').each(function(e){
													var reveal = $(this);
													var waypoint_reveal = new Waypoint({
														element: reveal[0],
														handler: function(direction) {
															if (direction === 'down'){
																reveal.addClass('on');
															}
														},
														offset: '95%'
													});
												});
											}, 100);
										}
									});
								};
				});

				var ajaxpagination4 = {
					"ajaxurl":"/wp-admin/admin-ajax.php"
				};

				$('#reset').on('click', function() {
					$('.insight-filter .topics > li > a .current').text('Featured Topics');
					$('.insight-filter .types > li > a .current').text('All Types');
					$('.topic').removeClass('activeTopic');
					$('.type').removeClass('activeType');

					$.ajax({
						url: ajaxpagination4.ajaxurl,
						type: 'post',
						cache: false,
						data: {
							action: 'all_insights_reset',
						},
							beforeSend: function() {
							$('#insight-section').append('<div class="load-more-content"><div class="load-more"></div></div>');
							$('.blue-circle-large.three').remove();
							$('.blue-shape-small.two').remove();
						},
							success: function(insightFilter) {
							waypoint.destroy();
							$('.load-more-content').remove();
							$('#insight-section').empty();
							$("#insight-section").append(insightFilter);
						},
						//After the call is made we re-run the waypoint reveal function again to reveal the newly loaded insights
						complete: function() {
							setTimeout(function(){
								$('.reveal').each(function(e){
									var reveal = $(this);
									var waypoint_reveal = new Waypoint({
										element: reveal[0],
										handler: function(direction) {
											if (direction === 'down'){
												reveal.addClass('on');
											}
										},
										offset: '95%'
									});
								});
							}, 100);
						}
					});
				});

				$(window).on('load', function() {
					if ($('.banner.hero').length > 0){
						$('.banner.hero .cnt .subheader, .banner.hero .cnt h1, .banner.hero .bg').addClass('on');
						setTimeout(function(){
							$('.banner.hero .btn').addClass('on');
						}, 600);
					}
					if ($('.banner.inside').length > 0){
						$('.banner.inside .cnt .page-name, .banner.inside .cnt h1').addClass('on');
					}
					if ($('.banner.insight').length > 0){
						$('.banner.insight .cnt .page-name, .banner.insight .cnt h1, .banner.insight .cnt .author, .banner.insight .cnt .excerpt, .banner.insight .cnt .read-more, .banner.insight .thumb, .insight-filter').addClass('on');
					}
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

			}
		},

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
